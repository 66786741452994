@import "../../styles/variables";

.form {
  height: calc(100% - 30px);
  overflow-y: auto;
  padding: 1rem;
  margin: -1rem;
}

.filterHeading {
  margin-block-start: 0;
}

.headingLabel {
  color: map-deep-get($colors, "brand-colors", "secondary");
  font-weight: 700;

  svg {
    margin-inline-start: 4px;
    width: 16px;
    height: 16px;
  }
}

.locationDropDown {
  position: relative;

  input {
    padding-inline-start: 0;
    padding-inline-end: 32px;
  }
}

.locationInput {
  position: relative;

  > svg {
    position: absolute;
    bottom: 11px;
    left: 0;
    fill: map-deep-get($colors, "grays", 400);
    width: 16px;
    height: 16px;
    margin: auto;
    z-index: 1;
  }

  > button {
    top: auto;
    bottom: 10px;
  }

  input {
    padding-inline-start: 20px;
    padding-inline-end: 70px;
    border: 0;
    border-bottom: 1px solid map-deep-get($colors, "grays", 100);
    height: 36px;
    width: 100%;
    color: #333;
  }
}

.typeList {
  > div [role="tablist"] {
    justify-content: flex-start;

    [class^="rc-tabs-nav-list"] {
      &::after {
        background-color: f3f4f6;
      }
    }
  }

  div[class="rc-tabs-tab-btn"] {
    font-size: 1rem;
  }
}

.formFixBtn {
  background-color: #fff;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, .1);
  padding: 1rem;
  position: fixed;
  bottom: 0;
  inset-inline: 0;
  z-index: 2;
}

.purposeButton {
  button {
    padding: 10px 22px;
    font-size: .9rem;
  }
}

.slidePropertyType {
  [class^="rc-tabs-tabpane"] {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  [class^="property-type-tab_tabContents"] {
    flex-wrap: initial;
  }

  [class^="property-type-tab_tabItem"] {
    margin-inline-end: 8px;
  }
}
