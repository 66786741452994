@import "../../styles/variables";

.rangeInput {
  flex: 48% 0 0;

  .filterInput {
    font-size: 1rem;
    border: 0;
    border-bottom: 1px solid map-deep-get($colors, "grays", 100);
    border-radius: 0;
    padding: 0 0;
    width: 100%;
    height: 36px;
  }

  .filterInput[type=number] {
    -moz-appearance: textfield;
  }

  .filterInput::-webkit-outer-spin-button,
  .filterInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .label {
    display: block;
  }

  .textRight {
    text-align: right;
  }
}

.close {
  font-weight: 700;
  cursor: pointer;
  color: #696969;

  &:hover {
    color: map-deep-get($colors, "brand-colors", "primary");
  }
}
