@import "../../styles/variables";

.searchBar {
  padding: 16px;
  border-block: 1px solid map-deep-get($colors, "grays", 100);
  background-color: #fff;
  position: sticky;
  top: 0;
  inset-inline: 0;
  width: 100%;
  z-index: 9;

  @media (min-width: $screen-portrait-max + 1) {
    display: none;
  }
}

.inputBar {
  position: relative;
  box-shadow: 0 2px 4px -2px rgba($color: #000, $alpha: .24);
  height: 40px;
  padding-inline-start: 40px;
  border-radius: 4px;
  border: 1px solid map-deep-get($colors, "grays", 200);
  background-color: #fff;
  width: 100%;
  color: map-deep-get($colors, "grays", 400);

  input {
    border: 0;
    height: 38px;
    font-size: 1rem;
  }

  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    inset-block: 0;
    left: 12px;
    margin: auto;
    fill: map-deep-get($colors, "grays", 400);
  }
}

.filterSheet {
  overflow-y: initial;
}

.homeSearchBar {
  color: #fff;
}

.citySheetScroll {
  overflow-y: scroll;
}
