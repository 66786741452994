@import "../../styles/variables";

.btnList {
  font-size: .95rem;
  font-weight: 700;
  align-items: center;
  justify-content: flex-end;

  .close {
    font-weight: 700;
    cursor: pointer;
    color: #696969;

    &:hover {
      color: map-deep-get($colors, "brand-colors", "primary");
    }
  }
}
