@import "../../styles/variables";

.tabContents {
  flex-wrap: wrap;

  .tabItem {
    border: 1px solid map-deep-get($colors, "grays", 100);
    border-radius: 4px;
    padding: 6px 8px;
    white-space: nowrap;
    color: map-deep-get($colors, "grays", 400);
    cursor: pointer;
    font-size: .85rem;
    text-transform: capitalize;
    flex: 49% 0 0;
    margin-block-start: 8px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.selected {
      border-color: map-deep-get($colors, "brand-colors", "secondary");
      color: #fff;
      background-color: map-deep-get($colors, "brand-colors", "secondary");

      svg {
        fill: currentColor;
      }
    }

    svg {
      width: 22px;
      height: 22px;
      position: relative;
      margin-right: 8px;
      fill: #a7a7a7;
    }
  }
}

.propertyTypeTabList {
  [class^="rc-tabs-nav-wrap"] {
    margin-block-end: 0;
  }

  [class^="rc-tabs-nav-list"] {
    &::after {
      display: none;
    }

    [class^="rc-tabs-tab"] {
      font-size: 1rem;
      padding-bottom: 0;
    }

    [class^=".rc-tabs-top .rc-tabs-ink-bar, .rc-tabs-bottom .rc-tabs-ink-bar"]{
      height: 2px;
    }
  }


}
